/* You can add global styles to this file, and also import other style files */

@font-face {
	font-family: "exo";
	src: url("./assets/fonts/exo/Exo-Regular.ttf");
}

@font-face {
	font-family: "exo";
	font-weight: bold;
	src: url("./assets/fonts/exo/Exo-Bold.ttf");
}

@font-face {
	font-family: "Mary Jane Olyff";
	src: url("./assets/fonts/mary-jane-olyff/MaryJaneOlyff.ttf");
}

html,
body {
	width: 100vw;
	height: 100vh;
}
body {
	overflow: hidden;
	margin: 0;
	padding: 0;
	font-family: "exo";
	background-color: rgba(42, 42, 42, 1);
}

p {
	padding: 0;
	margin-block-start: 0;
	margin-block-end: 0;
	font-size: 1em;
	font-family: "exo";
}

/* ************** */
/* *	MAP		* */
/* ************** */

.leaflet-control-zoom a,
.leaflet-tooltip {
	background-color: rgb(42, 42, 42);
	color: #fff;
}

.leaflet-tooltip::before {
	border-top-color: rgb(42, 42, 42);
}

.leaflet-tooltip.selected {
	background-color: #fff;
	color: rgb(42, 42, 42);
	border-color: rgb(42, 42, 42);
	z-index: 1;
}

/* ********************** */
/* *	MAT-SLIDER		* */
/* ********************** */

.mat-slider-wrapper {
	height: 1em;
}

.mat-slider-wrapper .mat-slider-track-wrapper {
	height: 100%;
}

.mat-slider-wrapper .mat-slider-track-background {
	height: 100%;
}

.mat-slider-wrapper .mat-slider-track-fill {
	height: 100%;
	background-color: #466c55;
}

.mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb {
	width: 3em;
	height: 3em;
	bottom: -1.5em;
	background-color: #466c55;
}

.mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb-label {
	width: 3em;
	height: 3em;
	top: -4em;
	right: -1.5em;
	background-color: #466c55;
}

.mat-slider-wrapper
	.mat-slider-thumb-container
	.mat-slider-thumb-label
	.mat-slider-thumb-label-text {
	font-size: 1.2em;
}

@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
	/* ************** */
	/* *	MAP		* */
	/* ************** */

	.leaflet-control-zoom a,
	.leaflet-tooltip {
		font-size: 1.4em;
	}

	.container-map .leaflet-pane.leaflet-marker-pane > img {
		height: 3em;
		width: auto;
	}
}

@media (min-width: 1024px) and (max-width: 1920px) and (orientation: portrait) {
	/* ************** */
	/* *	MAP		* */
	/* ************** */

	.leaflet-control-zoom a,
	.leaflet-tooltip {
		font-size: 1.8em;
	}

	.container-map .leaflet-pane.leaflet-marker-pane > img {
		height: 4em;
		width: auto;
	}
}
